import React, { ReactElement } from "react";
import { Helmet } from "react-helmet";
import BackButton from "./BackButton";
import CopyButton from "./CopyButton";
import NarrowContent from "./NarrowContent";

const Contact = (): ReactElement => (
  <div className="app-page">    
    <Helmet>
      <title>Mixopia | Contact</title>
    </Helmet>
    <NarrowContent>
      <BackButton onClick={() => window.history.back()} />
      <h1>Contact</h1>
      <h2>We&apos;d love to hear from you.</h2>
      <p>
        Please contact the Mixopia team if you&apos;d like to give us any
        feedback on your Mixopia experience or if you have any issues that
        require support.
      </p>
      <div>
        <CopyButton
          className="mr-2"
          id="copyEmailButton"
          value="support@mixopia.com"
          title="Email"
        />
        <a href="mailto:support@mixopia.com">support@mixopia.com</a>
      </div>
    </NarrowContent>
  </div>
);

export default Contact;
