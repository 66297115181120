import React, { ReactElement } from "react";
import { Button, ButtonProps } from "reactstrap";
import { FaChevronLeft } from "react-icons/fa";

const BackButton = (props: ButtonProps): ReactElement => (
  <Button
    className="btn-variant btn-tertiary text-decoration-none pl-0 mb-2"
    size="sm"
    {...props}
  >
    <FaChevronLeft size="16" />
    {props.value ? (
      <span className="body-copy-3 fw-light">{props.value}</span>
    ) : null}
  </Button>
);

export default BackButton;
