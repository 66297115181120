import React, { ReactElement } from "react";
import { useMediaQuery } from "react-responsive";

export interface VideoContentProps {
  name: string;
}

export const VideoContent = (props: VideoContentProps): ReactElement => {
  const isDesktop = useMediaQuery({ minWidth: 1280 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1279 });
  const isLandscape = useMediaQuery({ orientation: "landscape" });
  const prefix = `${process.env.PUBLIC_URL}/animations/${props.name}/${props.name}`;
  let source = prefix + "-mobile.mp4";
  if (isTablet && !isLandscape) source = prefix + "-tablet-P.mp4";
  if (isTablet && isLandscape) source = prefix + "-tablet-L.mp4";
  if (isDesktop) source = prefix + "-desktop.mp4";
  return (
    <div className="app-image-content">
      <video
        muted
        autoPlay
        playsInline
        loop
        src={source}
        title={props.name}
      />
    </div>
  );
};
