import React, { ReactElement } from "react";
import ActionsWrapper from "./ActionsWrapper";
import { Button, Container } from "reactstrap";
import { Helmet } from "react-helmet";

const Privacy = (): ReactElement => (
  <div className="app-page">
    <Helmet>
      <title>Mixopia | Privacy</title>
    </Helmet>
    <Container>
      <h1>Privacy Policy</h1>
      <p>
        In this Privacy Policy, &quot;us&quot; &quot;we&quot; or &quot;our&quot;
        means Life Tracks Events Pty Ltd ABN 77 638 523 501. We are the provider
        of the Mixopia Services (as defined in our Terms Of Service) We respect
        your privacy and right to control your personal information. Personal
        information includes information or an opinion about an individual that
        is reasonably identifiable. For example, this may include your name,
        age, gender, and contact details. It may also include financial
        information, including your credit card information.
      </p>
      <h2>Our guiding principles are simple:</h2>
      <p>
        We use personal information to serve our customers. We collect only what
        we need. We protect the personal information of our customers We meet and
        exceed the mandatory statutory requirements. The primary purpose of
        collecting your personal information and other data related to your use
        of the Mixopia Services is to improve your Mixopia experience. It is
        information and data that helps us build exciting playlists, gives you
        the ability to find relevant songs by having better suggestive search
        patterns, enables rich conversations with the Mixopia community and
        facilitates the improvement of Mixopia. We only collect your personal
        information for clearly defined purposes and will we not sell your
        personal information to third parties.
      </p>
      <h2>The types of personal information we collect</h2>
      <h3>Information you provide to us</h3>
      <p>
        We collect information you voluntarily provide to us, such as your name,
        age , gender, address, email address, IP address, phone number, payment
        information when you approach us (or opt in) to learn more about Mixopia
        or join the Mixopia community.{" "}
      </p>
      <h3>Information we collect automatically</h3>
      <p>
        We automatically collect information about you, such as Usage Data (see
        below) and Additional Usage Data (see below) about your use of the
        Mixopia Services collected by using cookies and similar technologies
        when you use, access, or interact with us via the Mixopia Services. We
        may, for example, collect information about the type of device you use
        to access the Mixopia Services, the operating system and version, your
        IP address, your general geographic location as indicated by your IP
        address, your browser type, the webpages you view on the Mixopia
        Services, and whether and how you interact with content available on the
        Mixopia Services.
      </p>
      <h3> Information obtained from other sources</h3>
      <p>
        We may receive information about you from other sources, including third
        parties, such as music service partners and partners with whom we offer
        co-branded services or engage in joint-marketing activities. We may also
        receive information about you from social media platforms, for instance,
        when you interact with us on social media. We protect data obtained from
        third parties according to the practices described in this Privacy
        Policy and we also apply any additional restrictions imposed by the
        source of data.
      </p>
      <h2>Information of children</h2>
      <p>
        We do not knowingly collect or request personal information from those
        under the age of sixteen without parental consent. If this happens
        inadvertently we will delete all information.
      </p>
      <h2>Usage Data</h2>
      <p>
        The main information we collect from your use of Mixopia include – Music
        streaming service information – a premium streaming service is required
        to use Mixopia to enable the diverse choice of songs to be accessed and
        played; Category and songs choices – to help us refine search
        functionality and suggest more relevant choices of topics and songs to
        users; Patterns of use or activity information; Feedback from you on
        your experience (eg by conducting customer surveys or by encouraging
        customers to review our services). Additional Usage Data In order to
        improve your experience with Mixopia and for the development of
        improved, personalised Mixopia Services, we may collect information
        related to your use of the Mixopia Services including the time of use,
        duration of use , frequency of use, interactions within the Mixopia
        Services and devices used to interact with them.
      </p>
      <h2>How We Use Personal Information</h2>
      <p>
        We may collect, hold, use and disclose your personal information for the
        following purposes: to enable you to access and use the Mixopia
        Services; to operate, protect, improve and optimise the Mixopia
        Services, our business and our users’ experience, such as to perform
        analytics, conduct research and for advertising and marketing; to send
        you service, support and administrative messages, reminders, technical
        notices, updates, security alerts, and information requested by you; to
        send you marketing and promotional messages and other information that
        may be of interest to you, including information sent by, or on behalf
        of, our business partners that we think you may find interesting; to
        administer rewards, surveys, contests, or other promotional activities
        or events sponsored or managed by us or our business partners; and to
        comply with our legal obligations, resolve any disputes that we may have
        with any of our users, and enforce our agreements with third parties; to
        communicate with you and to seek feedback and suggestions about new
        products and developments; to develop aggregate analysis and business
        intelligence that enable us to operate, protect, make informed
        decisions, and report on the performance of our business. We may also
        disclose your personal information to a trusted third party who also
        holds other information about you. This third party may combine that
        information in order to enable it and us to develop anonymised consumer
        insights so that we can better understand your preferences and
        interests, personalise your experience and enhance the products and
        services that you receive. We may use data related to your use of
        Mixopia to: Keep a record of your past events &amp; song selections, as
        a guest or host, to help provide better search findings and make setting
        up events easier; Aggregate data so we can publish a summary of
        selections, for example most popular funeral song, wedding song or best
        live band, in blogs, press releases and other marketing and promotional
        communications; To find out who our audience is (including age,
        demographics, socioeconomic group), and how they listen to music &amp;
        use Mixopia; and To track customer behaviour.
      </p>
      <h2>How We May Share Personal Information</h2>
      <p>
        We do not sell personal information about our customers. We only
        disclose your personal information as described in this Privacy Policy.
        Do we use your personal information for direct marketing? We may send
        you direct marketing communications and information about products and
        services we offer, including software add-ons and in-app purchases. This
        may take the form of emails, SMS, mail or other forms of communication,
        in accordance with the Spam Act and the Privacy Act. You may opt-out of
        receiving marketing materials from us by contacting us using the details
        set out below or by using the opt-out facilities provided (eg an
        unsubscribe link). To whom do we disclose your personal information? We
        may disclose personal information for the purposes described in this
        privacy policy to: our employees and related bodies corporate; third
        party suppliers and service providers (including providers for the
        operation of the Mixopia Services and/or our business or in connection
        with providing our products and services to you); professional advisers,
        dealers and agents; payment systems operators (eg merchants receiving
        card payments); our existing or potential agents, business partners or
        partners; our sponsors or promoters of any competition that we conduct
        via our services; anyone to whom our assets or businesses (or any part
        of them) are transferred; specific third parties authorised by you to
        receive information held by us; and/or other persons, including
        government agencies, regulatory bodies and law enforcement agencies, or
        as required, authorised or permitted by law. Disclosure of personal
        information outside Australia We may disclose personal information
        outside of Australia to cloud providers and other service providers
        located in countries other than Australia. When you provide your
        personal information to us, you consent to the disclosure of your
        information outside of Australia and acknowledge that we are not
        required to ensure that overseas recipients handle that personal
        information in compliance with Australian privacy law. We will, however,
        take reasonable steps to ensure that any overseas recipient will deal
        with such personal information in a way that is consistent with the
        Australian Privacy Principles.
      </p>
      <h2>Security of data</h2>
      <p>
        We are committed to protecting the security of your personal
        information. We use a variety of appropriate technical and
        organisational measures and industry standards to protect your personal
        information and help prevent information about you from loss, theft,
        misuse, and unauthorised access, disclosure, alteration, and
        destruction. Storage of personal information Personal information is
        stored on secured cloud based platformed. The security of information
        extends to all stages of the information life cycle, from the time of
        creation, while it is actively used, to archiving and destruction.
        Accessing or correcting your personal information You can access the
        personal information we hold about you by contacting us using the
        information below. Sometimes, we may not be able to provide you with
        access to all of your personal information and, where this is the case,
        we will tell you why. We may also need to verify your identity when you
        request your personal information. If you think that any personal
        information we hold about you is inaccurate, please contact us and we
        will take reasonable steps to ensure that it is corrected. Making a
        complaint If you think we have breached the Privacy Act, or you wish to
        make a complaint about the way we have handled your personal
        information, you can contact us using the details set out below. Please
        include your name, email address and/or telephone number and clearly
        describe your complaint. We will acknowledge your complaint and respond
        to you regarding your complaint within a reasonable period of time. If
        you think that we have failed to resolve the complaint satisfactorily,
        we will provide you with information about the further steps you can
        take.
      </p>
      <h3>Deletion of Personal Data</h3>
      <p>You can delete your personal data directly in the <a href="https://play.mixopia.com/Identity/Account/Manage/PersonalData">Personal Data</a> section of your user profile.
          Deleting this data will permanently remove your account, and this cannot be recovered.</p>
      <p>
        Contact Us For further information about our Privacy Policy or
        practices, or to access or correct your personal information, or make a
        complaint, please contact our Privacy Officer at the following email
        address: support@mixopia.com
      </p>
      <ActionsWrapper>
        <Button onClick={() => window.history.back()}>Back</Button>
      </ActionsWrapper>
    </Container>
  </div>
);

export default Privacy;
