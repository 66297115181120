import React, { ReactElement } from "react";
import { Row, Col, Container, Button } from "reactstrap";
import NarrowContent from "./NarrowContent";
import ActionsWrapper from "./ActionsWrapper";
import { VideoContent } from "./VideoContent";
import { SoundtrackCarousel } from "./SoundtrackCarousel";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Home = (): ReactElement => (
  <div>
    <Helmet>
      <title>Mixopia | Home</title>
    </Helmet>
    <VideoContent name="Themes" />
    <NarrowContent className="my-3 text-center">
      <h1>Soundtrack your life</h1>
      <p>
        Soundtrack your next event or gathering with themed, collaborative
        playlists to relive music memories and create new ones.
      </p>
      <ActionsWrapper>
        <a className="btn btn-primary" href="https://play.mixopia.com/events">
          Create your first event
        </a>
        <a
          className="btn btn-secondary"
          href="https://play.mixopia.com/authentication/login"
        >
          Login
        </a>
      </ActionsWrapper>
    </NarrowContent>
    <Container className="home-howto">
      <h1>How To Mixopia</h1>
      <Row>
        <Col>
          <h2>01</h2>
          <p>
            Enter date, time
            <br />
            and event details
          </p>
        </Col>
        <Col>
          <h2>02</h2>
          <p>
            Choose your
            <br />
            playlist themes
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>03</h2>
          <p>
            Send Mixopia event
            <br />
            link to guests
          </p>
        </Col>
        <Col>
          <h2>04</h2>
          <p>
            Select tracks
            <br />
            for each theme
          </p>
        </Col>
      </Row>
      <h1>Enjoy everyone&apos;s music!</h1>
    </Container>
    <SoundtrackCarousel />
    <NarrowContent className="my-3 text-center">
      <h1 className="alert-header">Mixopia Beta Program</h1>
      <h1>We’re providing free access to Mixopia in our beta phase.</h1>
      <p>
        In return we’d love feedback on your experience, the functionality and
        usability of our platform and any suggested improvements so we can make
        it as easy as possible for people to share and celebrate the soundtrack
        of their lives
      </p>
    </NarrowContent>

    <ActionsWrapper>
      <a
        className="btn btn-primary"
        href="https://play.mixopia.com/authentication/register"
      >
        Register Now
      </a>
    </ActionsWrapper>
    <VideoContent name="Boombox" />
    <ActionsWrapper>
      <Button tag={Link} color="primary" to="/explore">
        Explore More
      </Button>
    </ActionsWrapper>
  </div>
);

export default Home;
