import React, { ReactElement } from "react";
import ActionsWrapper from "./ActionsWrapper";
import { Button, Container } from "reactstrap";
import { Helmet } from "react-helmet";

const Terms = (): ReactElement => (
  <div className="app-page">
    <Helmet>
      <title>Mixopia | Terms</title>
    </Helmet>
    <Container>
      <h1>Mixopia – Terms of service</h1>
      <h2>1. Introduction</h2>
      <p>
        1.1 Please read these Terms of Service (“Terms”) carefully before using
        Mixopia.
      </p>
      <p>
        1.2 Mixopia is an online platform that enables hosts to create
        collaborative, themed playlists on their Spotify Premium or Apple Music
        service with their friends, family and colleagues for any event, special
        occasion or social gathering. Hosts select playlist themes and invite
        guests to remotely search for songs and add their selections to each
        playlist. Typically, Mixopia playlists are themed around life
        experiences and music memories. Mixopia lets customers curate the
        soundtrack of their life so they can store their most important music
        tracks and memories. Mixopia provides collaborative playlist building
        capacity for use globally.
      </p>
      <p>
        1.3 In these Terms, “Mixopia Services” means any websites, mobile
        applications, and other services under the control of Life Tracks Events
        Pty Ltd ABN 77 638 523 501 (“we”, “us”), related to providing an online,
        mobile, or other digital platform for Mixopia, including www.mixopia.com
        and the Mixopia app.
      </p>
      <p>
        1.4 These Terms constitute a binding agreement between you and us.
        “You”, “user”, and “users” shall mean all visitors and customers to and
        users of the Mixopia Services. You accept these terms each time you use
        the Mixopia Services. If you do not accept these Terms, you must not use
        the Mixopia Services. If you are under 18 years of age, you represent
        and agree you possess the legal consent of your parent or guardian to
        access and use the Mixopia Services and in any event you represent that
        you are otherwise able to enter into a valid and binding contract.
      </p>
      <p>1.5 Our Privacy Policy forms part of these Terms.</p>
      <p>
        1.6. We may revise these Terms at any time by posting an updated
        version. You should visit this page periodically to review the most
        current Terms, because you are bound by them. Your continued use of the
        Mixopia Services after a change to these Terms constitutes your binding
        acceptance of these Terms.
      </p>
      <p>
        1.7 The terms “post” and “posting” as used in these terms shall mean the
        act of submitting, uploading, publishing, displaying, or similar action
        on the Mixopia platforms. For instance, as a customer or registered user
        you may occasional post your playlists by uploading and/or linking it to
        the Mixopia platform or social channels.
      </p>
      <p>
        1.8 “Personal Information” as used in these Terms has the same meaning
        as in our Privacy Policy
      </p>
      <h2>2. Important terms regarding connecting to a streaming service</h2>
      <p>
        2.1 Mixopia is not affiliated with any particular streaming service. It
        is agnostic and will link to any willing and capable streaming service.
        2.2 Although we are independent from streaming companies, you may use
        Mixopia in connection with Spotify and Apple. If you do so, please note
        the following:
      </p>
      <p>
        2.3 We do not make any warranties or representations on behalf of these
        companies and expressly disclaims all implied warranties with respect to
        their services including the implied warranties of merchantability,
        fitness for a particular purpose and non-infringement.
      </p>
      <p>
        2.4 You are prohibited from using Mixopia to modify or create derivative
        works based on the streaming services’ platforms.
      </p>
      <p>
        2.5 We are responsible for Mixopia and disclaim any liability for
        Mixopia on the part of the Spotify and Apple Music streaming services.
      </p>
      <p>
        2.6 We are not responsible for Spotify or Apple Music, and we encourage
        you to consult their terms of service or to contact them directly if you
        have questions specifically about their services.
      </p>
      <h2>3. The Mixopia Services</h2>
      <p>
        3.1 We grant you permission to use the Mixopia Services subject to the
        restrictions in these Terms. Your use of the Mixopia Services is at your
        own risk, including the risk that you might be exposed to content that
        is offensive, indecent, inaccurate, objectionable, or otherwise
        inappropriate.
      </p>
      <p>
        3.2 Children under 13 are only eligible to use the Mixopia Services
        under the supervisor of adults.
      </p>
      <h2>4. Your Mixopia account</h2>
      <p>
        4.1 You are responsible for your log-in credentials and for keeping your
        information accurate. You are responsible for any activity resulting
        from the use of your log-in credentials on the Mixopia Services.
      </p>
      <p>
        4.2 You represent and warrant that the information you provide to us
        when signing up and at all other times will be true, accurate, current,
        and complete.
      </p>
      <p>
        4.3 To use the Mixopia Services, you will have log-in information,
        including a username and password. Your account is personal to you, and
        you may not share your account information with, or allow access to your
        account by, any third party. As you will be responsible for all activity
        that occurs under your access credentials, you agree to use reasonable
        efforts to prevent unauthorized access to or use of the Mixopia Services
        and to preserve the confidentiality of your username and password, and
        any device that you use to access the Mixopia Services. You agree to
        notify us immediately of any breach of your log-in information. If you
        have any reason to believe that your account information has been
        compromised or that your account has been accessed by a third party, you
        agree to immediately notify us by e-mail to legal@mixopia.com.
      </p>
      <h2>5. Communications</h2>
      <p>
        5.1 We may communicate with you by email or posting notices on the
        Mixopia Services or our social platforms.
      </p>
      <p>
        5.2 You agree to receive emails from us at the email address you
        provided to us for customer service-related purposes and for other
        purposes contemplated by these terms and the Privacy Policy.
      </p>
      <p>
        5.3 By using the Mixopia Services or providing personal information to
        us, you agree that we may communicate with you electronically regarding
        security, privacy, and administrative issues relating to your use of the
        Mixopia Services. If we learn of a security system’s breach, we may
        attempt to notify you electronically by sending an email to you. You may
        have a legal right to receive this notice in writing. To receive written
        notice of a security breach (or to withdraw your consent from receiving
        electronic notice), please write to us at legal@mixopia.com
      </p>
      <h2>6. Purchases</h2>
      <p>
        6.1 By subscribing to or purchasing our services, you represent that the
        services ordered will be used only in a lawful manner.
      </p>
      <p>
        6.2 You agree to pay all charges that may be incurred by you or on your
        behalf through the Mixopia Services and/or our authorized payment
        vendor, at the price(s) in effect when such charges are incurred,
        including without limitation all processing charges.
      </p>
      <p>
        6.3 We do not offer refunds other than at our discretion in exceptional
        circumstances.
      </p>
      <h2>7. Promotions.</h2>
      <p>
        7.1 From time to time, we may offer contests, and other promotional
        events in which you may choose to participate (“Promotions”). By
        participating in any such Promotion, you become subject to the rules for
        such Promotions as published by us, which may vary from these Terms. You
        should carefully review the rules, if any, of each Promotion in which
        you participate through the Mixopia Services. These terms will remain in
        full force and effect as long as you remain a user of the Mixopia
        Services.
      </p>
      <h2>8. Content ownership and use</h2>
      <p>
        8.1 We own or have the right to use all the Mixopia Content we make
        available on the Mixopia Services. “Mixopia Content” includes designs,
        text, graphics, images, video, information, logos, button icons,
        software, audio files and computer code, but does not include the music
        or other content provided by streaming services which you connect to via
        Mixopia.
      </p>
      <p>
        8.2 The Mixopia Content and the compilation (meaning the collection,
        arrangement, and assembly) of all Mixopia Content are the property of us
        or our licensors, and are protected under copyright, trademark, and
        other laws. Except as expressly authorized in writing by us, you agree
        not to modify, copy, frame, scrape, rent, lease, loan, sell, distribute,
        or create derivative works based on the Mixopia Content in whole or in
        part.
      </p>
      <p>
        8.3 We authorize you, subject to these Terms, to access and use the
        Mixopia Services and Mixopia Content solely for your personal use and do
        not permit use of any data mining, robots, scraping, or similar data
        gathering or extraction methods. Any other use is expressly prohibited.
        This license is revocable at any time without notice and with or without
        cause. Unauthorized use of the Mixopia Services or Mixopia Content may
        violate copyright, trademark, and applicable communications regulations
        and statutes and is strictly prohibited. You must preserve all
        copyright, trademarks, service marks, and other proprietary notices
        contained in the mixopia platforms. You cannot use our logo without our
        written permission.
      </p>
      <h2>9. Suggestions and submissions</h2>
      <p>
        9.1 We appreciate hearing from our users and welcome your comments
        regarding the Mixopia Services. Please be advised, however, that if you
        send us creative ideas, suggestions, inventions, or materials (“creative
        ideas”), we shall: (a) own, exclusively, all now known or later
        discovered rights to the creative ideas; (b) not be subject to any
        obligation of confidentiality and shall not be liable for any use or
        disclosure of any creative ideas; and (c) be entitled to unrestricted
        use of the creative ideas for any purpose whatsoever, commercial or
        otherwise, without compensation to you or any other person.
      </p>
      <h2>10. Mixopia Services limitations and prohibitions</h2>
      <p>
        10.1 You agree to use the Mixopia Services only for their intended
        purpose. You must use the Mixopia Services in compliance with all
        privacy, data protection, intellectual property, and other applicable
        laws. You must not: (a) attempt to interfere with, harm, reverse
        engineer, steal from, or gain unauthorized access to the Mixopia
        Services, user accounts, or the technology and equipment supporting the
        Mixopia Services; (b) frame or link to the Mixopia Services without
        permission; (c) use data mining, robots, or other data gathering devices
        on or through the Mixopia Services; (d) post incomplete, false, or
        misleading information, impersonate another person, or misrepresent your
        affiliation with a person or entity; (e) disclose personal information
        about another person; (f) harass, threaten, stalk, abuse, or post
        objectionable content; (g) sell, transfer, or assign any of your rights
        to use the Mixopia Services to a third party without our express written
        consent; (h) post advertising or marketing links or content, except as
        specifically allowed by these Terms; (i) simulate or impersonate organic
        activities through the use of automated processes, inauthentic accounts,
        or other means we determine to be misleading or fraudulent, including,
        but not limited to, stream manipulation as defined by the Anti-Stream
        Manipulation Code of Best Practice. (j) use the Mixopia Services in an
        illegal way or to commit an illegal act in relation to the Mixopia
        Services or that otherwise results in fines, penalties, and other
        liability to us or others; or (k) access the Mixopia Services from a
        jurisdiction where it is illegal or unauthorized.
      </p>
      <h2>11. Termination and Suspension</h2>
      <p>
        11.1 If you breach these Terms, we may terminate your use of the Mixopia
        Services.
      </p>
      <p>
        11.2 We reserve the right to suspend or terminate your account and
        prevent access to the Mixopia Servicesfor any reason, at our discretion.
        We reserve the right to refuse to provide the Mixopia Services to you in
        the future.
      </p>
      <p>
        11.3 You are responsible for any claims, fees, fines, penalties, and
        other liability incurred by us or others caused by or arising out of
        your breach of these Terms.
      </p>
      <h2>12. Our liability</h2>
      <p>
        12.1 We are not liable for the actions of users when they use the
        Mixopia Services. We may also change the Mixopia Services at any time
        and are not liable for how this may affect you. We do not guarantee the
        quality or accuracy of any content you encounter using the Mixopia
        Services.
      </p>
      <p>
        12.2 We may change, suspend, or discontinue any aspect of the Mixopia
        Services at any time, their availability, or any feature of them,
        without notice or liability.
      </p>
      <h2>13. Third-Party Websites.</h2>
      <p>
        13.1 The Mixopia Services may include links to third party websites,
        applications and services. You are responsible for evaluating whether
        you want to access or use them. We are not responsible for and do not
        endorse any features, content, advertising, products, or other materials
        on other websites or applications. You assume all risk and we disclaim
        all liability arising from your use of them.
      </p>
      <h2>14. Indemnity</h2>
      <p>
        14.1 You indemnify us, our related bodies corporate, subsidiaries,
        licensees and/or assignees, and their respective officers, agents,
        partners and employees in respect of any claims, loss, damage or costs
        (including legal costs on a full indemnity basis) arising from or in
        connection with: (a) any breach or alleged breach by you these Terms;
        (b) your use of the Mixopia Services; or (c) any violation by you of any
        law or the rights of any third party.
      </p>
      <h2>15. Limitation of Liability</h2>
      <p>
        15.1 Nothing in these Terms excludes, restricts, or modifies any rights
        that you have under the Competition and Consumer Act 2010 (Cth).
      </p>
      <p>
        15.2 To the maximum extent permitted by law (but subject to clause
        15.1): (a) we make no warranties of any kind, express or implied, about
        the Mixopia Services, including but not limited to any warranties: (i)
        about the accuracy and reliability of any material contained in the
        Mixopia Services; (ii) that the Mixopia Services will meet your
        requirements or achieve any particular result; (ii) that your access to
        or use of the Mixopia Services will be free from errors, defects, bugs
        or viruses, uninterrupted, or reparable if damaged or impaired; b) we
        are not liable to you or any other person in respect of any interference
        with or damage to your computer system or any other device which occurs
        in connection with your use of the Mixopia Services; and (c) we are not
        responsible for any technical problems or malfunction of any telephone
        network or lines, online systems, servers, providers, hardware,
        software, due to technical problems or traffic congestion on the
        internet.
      </p>
      <p>
        15.3 To the maximum extent permitted by law, in no event shall we be
        liable for any direct or indirect loss, damage or expense, loss of
        profits, loss of revenue, loss of data, loss of or damage to reputation,
        loss of or damage to goodwill, loss of business opportunities, loss of
        management time, damage to credit rating, loss of business, or any other
        loss or damage – irrespective of the manner in which it occurs – which
        may be suffered due to a your use of the Mixopia Services, or as a
        result of the inaccessibility of the Mixopia Services and/or the fact
        that certain information or materials contained on them are incorrect,
        incomplete or not up-to-date.
      </p>
      <p>
        15.4 Certain legislation may imply warranties or conditions, impose
        obligations or give statutory guarantees (together, “Statutory
        Provisions”) which cannot be excluded, restricted or modified except to
        a limited extent. These Terms must be read subject to the Statutory
        Provisions. If the Statutory Provisions apply, notwithstanding any other
        provision of these Terms, to the extent to which we are entitled to do
        so, we limit our liability in respect of any claim in relation to
        services supplied by us to (at our election): the resupply of the
        services to you; or the payment of the cost of having the services
        supplied to you again.
      </p>
      <h2>16. Entire agreement</h2>
      <p>
        16.1 These Terms, and the terms of any other notices or instructions
        given to you under these Terms supersede all prior agreements,
        representations (whether oral or written), and understandings and
        constitute the entire agreement between you and us relating to the use
        of the Mixopia Services and the other matters dealt with in these Terms.
      </p>
      <h2>17. No Assignment</h2>
      <p>
        17.1 You may not assign or transfer any rights under these Terms to any
        third party without our prior written consent.
      </p>
      <h2>18. Severability</h2>
      18.1 If any part or provision of these Terms is invalid, unenforceable, or
      in conflict with any law, that part or provision is excised from these
      Terms and the remainder of the Terms will be unaffected.
      <h2>19. Interpretation</h2>
      <p>
        19.1 In these Terms: (a) a reference to natural person includes a
        corporation, partnership, government body or any other legal entity; (b)
        words and expressions not otherwise defined and which are defined in the
        Copyright Act 1968 (Cth) will have the same meaning given to them in
        that Act; and (c) &quote;including&quote; and similar expressions are
        not words of limitation.
      </p>
      <h2>20. Jurisdiction and governing law </h2>
      <p>
        20.1 Your use of the Mixopia Services and These Terms are governed by
        the law of New South Wales, Australia and you submit to the
        non-exclusive jurisdiction of the courts exercising jurisdiction in New
        South Wales.
      </p>
      <h2>21. Force Majeure</h2>
      <p>
        21.1 To the fullest extent permitted under applicable law, we will be
        excused from performance under these Terms for any period that we arew
        prevented from or delayed in performing any obligations pursuant to
        these Terms, in whole or in part, as a result of a Force Majeure Event.
        For purposes of this section, “Force Majeure Event” means an event or
        series of events caused by or resulting from any of the following: (a)
        weather conditions or other elements of nature or acts of God; (b) acts
        of war, acts of terrorism, insurrection, riots, civil disorders or
        rebellion; (c) quarantines or embargoes, (d) Industrial strikes; (e)
        telecommunications, network, computer, server or Internet disruption or
        downtime; (f) unauthorized access to or access in excess of
        authorization of our information technology systems; or (g) other causes
        beyond our reasonable control.
      </p>
      <h2>22. Contact Information</h2>
      <p>
        22.1 The Mixopia Services are provided by Life Tracks Events Pty Ltd. If
        you have any questions, comments or complaints regarding these Terms or
        the Mixopia Services, feel free to contact us. Mixopia Level 8, 124
        Walker Street, North Sydney, NSW, 2060 support@mixopia.com
      </p>
      <ActionsWrapper>
        <Button onClick={() => window.history.back()}>Back</Button>
      </ActionsWrapper>
    </Container>
  </div>
);

export default Terms;
