import React, { Fragment, ReactElement } from "react";
import {
  Button,
  ButtonProps,
  PopoverBody,
  UncontrolledPopover,
} from "reactstrap";
import { FaCopy } from "react-icons/fa";

const copyText = (value: string): void => {
  navigator.clipboard.writeText(value);
};

const CopyButton = (props: ButtonProps): ReactElement => (
  <Fragment>
    <Button
      color="secondary"
      size="sm"
      onClick={(): void => copyText(props.value ? props.value.toString() : "")}
      {...props}
    >
      <FaCopy className="mr-1" size="16" />
      Copy
    </Button>
    <UncontrolledPopover
      trigger="legacy"
      placement="bottom"
      target={props.id || ""}
    >
      <PopoverBody>{props.title || "Text"} copied.</PopoverBody>
    </UncontrolledPopover>
  </Fragment>
);

export default CopyButton;
