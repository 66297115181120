import React from "react";
import { Route, Switch } from "react-router-dom";
import "./App.scss";
import Contact from "./components/Contact";
import Explore from "./components/Explore";
import Footer from "./components/Footer";
import HelpGuest from "./components/HelpGuest";
import HelpHost from "./components/HelpHost";
import Home from "./components/Home";
import NavMenu from "./components/NavMenu";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";

function App() {
  return (
    <div className="app-layout">
      <NavMenu />
      <Switch>
        <Route path="/terms">
          <Terms />
        </Route>
        <Route path="/privacy">
          <Privacy />
        </Route>
        <Route path="/explore">
          <Explore />
        </Route>
        <Route path="/help/guest">
          <HelpGuest />
        </Route>
        <Route path={["/help/", "/help/host"]}>
          <HelpHost />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
