import React from "react";

export interface ImageContentProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  backgroundColor?: string;
  objectFit?: "contain" | "cover";
  title?: string;
}

export const ImageContent = (props: ImageContentProps): React.ReactElement => {
  const wrapperStyle = {
    backgroundColor: props?.backgroundColor,
  };
  const imageStyle = {
    objectFit: props.objectFit ? props.objectFit : "contain",
  };
  return (
    <div className="app-image-content" style={wrapperStyle}>
      <img alt={props.title} style={imageStyle} {...props}/>
      <span className="alert-header">{props.title}</span>
    </div>
  );
};
