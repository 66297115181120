import React, { ReactElement } from "react";
import { Helmet } from "react-helmet";
import { Button } from "reactstrap";
import ActionsWrapper from "./ActionsWrapper";
import NarrowContent from "./NarrowContent";

const HelpHost = (): ReactElement => (
  <div className="app-page">
    <Helmet>
      <title>Mixopia | Help | Host</title>
    </Helmet>
    <NarrowContent>
      <h1>How it works</h1>
      <ol>
        <li>
          <p>
            <strong>Register/sign in</strong> and link your Spotify Premium or
            Apple Music Account.
          </p>
        </li>
        <li>
          <p>
            Enter your <strong>event details</strong> including the date, time,
            location and a description.
          </p>
        </li>
        <li>
          <p>
            Select <strong>playlist themes</strong> from our extensive
            pre-populated lists or make up your own.
          </p>
        </li>
        <li>
          <p>
            <strong>Invite guests</strong> to contribute by sharing your Mixopia
            event link on your preferred messaging service.
          </p>
        </li>
        <li>
          <p>
            After clicking the link, your guests will need to register to be
            able to <strong>search and select songs</strong> for each playlist
            theme.
          </p>
        </li>
        <li>
          <p>
            Make your own <strong>song selections</strong>.
          </p>
        </li>
        <li>
          <p>
            <strong>Track guest song selections</strong> and send a reminder if
            necessary.
          </p>
        </li>
        <li>
          <p>
            <strong>Start your event</strong> when everyone has made their
            selections. Note that no additions or changes will be allowed once
            you continue to the event music.
          </p>
        </li>
        <li>
          <p>
            <strong>Play the event playlists</strong> in your streaming music
            app. You can shuffle and play through each playlist theme.
          </p>
        </li>
        <li>
          <p>
            <strong>Have fun</strong> guessing who selected the songs and why.
          </p>
        </li>
        <li>
          <p>
            Reveal who selected each song by selecting&nbsp;
            <strong>Show Guest Names</strong> in the <strong>Event Music</strong> page in
            Mixopia.
          </p>
        </li>
        <li>
          <p>
            Return to Mixopia and <strong>finish the event.</strong>
          </p>
        </li>
        <li>
          <p>
            <strong>Share your event playlists</strong> with guests and friends.
          </p>
        </li>
      </ol>
      <ActionsWrapper>
        <Button onClick={() => window.history.back()}>Back</Button>
      </ActionsWrapper>
    </NarrowContent>
  </div>
);

export default HelpHost;
