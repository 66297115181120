import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import NarrowContent from "./NarrowContent";
import MixopiaLogo from "../images/MIXOPIA_LOGO_RGB_BETA.svg";
import FacebookIcon from "../images/FACEBOOK_ICON.svg";
import InstagramIcon from "../images/INSTAGRAM_ICON.svg";

const Footer = (): ReactElement => (
  <footer className="footer border-top">
    <NarrowContent className="text-center">
      <img
        height="24"
        src={MixopiaLogo}
        alt="Mixopia"
      />
      <div>
        <Link to="/terms">Terms of Service</Link>
      </div>
      <div>
        <Link to="/privacy">Privacy Policy</Link>
      </div>
      <div>
        <Link to="/contact">Contact Us</Link>
      </div>
      <div className="d-flex justify-content-around">
        <a href="https://www.facebook.com/mixopia">
          <img height="20px" src={FacebookIcon} alt="Facebook" />
        </a>   
        <a href="https://www.instagram.com/mixopia">
          <img height="20px" src={InstagramIcon} alt="Instagram" />
        </a>
      </div>
      <span>
        Copyright &copy; 2020-2023 Lifetracks Events Pty Ltd. All rights reserved
      </span>
    </NarrowContent>
  </footer>
);

export default Footer;
