import React, { ReactElement, HTMLAttributes } from "react";
import { Col, Container, Row } from "reactstrap";

const NarrowContent = (
  props: React.PropsWithChildren<HTMLAttributes<HTMLDivElement>>
): ReactElement => (
  <Container className="app-narrow-container" {...props}>
    <Row className="app-narrow-row">
      <Col
        sm={{ size: 10, offset: 1 }}
        md={{ size: 8, offset: 2 }}
        lg={{ size: 6, offset: 3 }}
      >
        {props.children}
      </Col>
    </Row>
  </Container>
);

export default NarrowContent;
