import React, { ReactElement } from "react";
import NarrowContent from "./NarrowContent";
import { ImageContent } from "./ImageContent";
import { Row, Col, Button } from "reactstrap";
import { Helmet } from "react-helmet";
import ActionsWrapper from "./ActionsWrapper";

const Explore = (): ReactElement => (
  <div className="app-explore-page">
    <Helmet>
      <title>Mixopia | Explore More</title>
    </Helmet>
    <ImageContent
      height="250px"
      src={process.env.PUBLIC_URL + "images/EXPLORE_MORE_HEADER_Mobile.jpg"}
      alt=" Soundtrack your next event"
      title=" Soundtrack your next event"
    />
    <NarrowContent className="my-3 text-center">
      <p>
        Do you dream of being able to quickly and easily build collaborative,
        themed playlists that are tailored to everyone’s music tastes?
      </p>
      <p>
        If so, MIXOPIA will help you soundtrack any social gathering from
        parties, BBQ’s and celebrations to road trips and hanging out with
        friends.
      </p>
      <hr />
      <h1>With MIXOPIA, you can create the perfect playlist by:</h1>
      <h2>Using your existing Spotify Premium or Apple Music account</h2>
      <Row>
        <Col>
          <img
            height="34px"
            alt="Spotify"
            src={process.env.PUBLIC_URL + "images/Spotify_Logo_RGB_Green.png"}
          />
        </Col>
        <Col>
          <img
            height="34px"
            alt="Apple Music"
            src={
              process.env.PUBLIC_URL + "images/US-UK_Apple_Music_Badge_RGB.svg"
            }
          />
        </Col>
      </Row>
      <p className="body-copy-2">
        If you have a Spotify Premium or Apple Music account, you can run a
        MIXOPIA event.
      </p>
      <hr />
      <h2>
        Choosing from our extensive library of playlist themes and/or adding
        your own
      </h2>
      <p className="body-copy-2">
        Create and edit your own tailored playlist themes or choose from our
        huge library of theme ideas that are based on life experiences and music
        memories.
      </p>
      <hr />
      <h2>Making music selections inclusive to everyone</h2>
      <p className="body-copy-2">
        Everyone attending can add songs to the event playlist(s) without
        needing a Spotify Premium or Apple Music account.
      </p>
      <hr />
      <h2>Choosing the music prior to your event</h2>
      <p className="body-copy-2">
        Once you’ve chosen your playlist themes, you can send out a MIXOPIA link
        with the event ID, enabling song selections to be made in advance.
      </p>
      <hr />
      <h2>Sharing the stories behind your song selections</h2>
      <p className="body-copy-2">
        Encourage your guests to share the stories behind their selections for a
        more meaningful music experience.
      </p>
      <ActionsWrapper>
        <Button onClick={() => window.history.back()}>Back</Button>
      </ActionsWrapper>
    </NarrowContent>
  </div>
);

export default Explore;
