import React, { ReactElement } from "react";
import { Helmet } from "react-helmet";
import { Button } from "reactstrap";
import ActionsWrapper from "./ActionsWrapper";
import NarrowContent from "./NarrowContent";

const HelpGuest = (): ReactElement => (
  <div className="app-page">
    <Helmet>
      <title>Mixopia | Help | Guest</title>
    </Helmet>
    <NarrowContent>
      <h1>How it works</h1>
      <ol>
        <li>
          <p>
            Click the <strong>Mixopia event link</strong> your host has sent you to join the event.
          </p>
        </li>
        <li>
          <p><strong>Register or sign in</strong> to your Mixopia account.</p>
        </li>
        <li>
          <p>
            <strong>Search and select songs</strong> for each playlist theme your host has
            chosen.
          </p>
        </li>
        <li>
          <p>
            You can <strong>edit your song selections</strong> any time until the event starts.
          </p>
        </li>
        <li>
          <p>
            Enjoy each song at the event and <strong>have fun guessing</strong> who selected them
            and why.
          </p>
        </li>
        <li>
          <p>Ask your host to <strong>share the playlists</strong>.</p>
        </li>
      </ol>
      <ActionsWrapper>
        <Button onClick={() => window.history.back()}>Back</Button>
      </ActionsWrapper>
    </NarrowContent>
  </div>
);

export default HelpGuest;
